<template>
	<ion-page>
		<ion-content :fullscreen="true">
			<div class="header">
				<ion-buttons slot="end">
					<ion-menu-button color="dark"></ion-menu-button>
				</ion-buttons>
				<a href="/app/" router-direction="root" nav-transition="none"><img src="/assets/images/main-logo.png"></a>
			</div>
			<div id="container">
				<div class="page-header">
					<a href="/app/" class="back-to-cat">
						<img src="/assets/images/chevron-back.svg" class="back-icon">
						<span v-if="this.$route.params.id === 'ubio'">uBio</span>
						<span class="capitalize" v-else>{{ this.$route.params.id }}</span>
					</a>
				</div>
				<div class="news-slider">
					<vueper-slides :dragging-distance="50" :slide-ratio="3 / 4" :infinite=true :arrows=false ref="newsSlider" v-if="categCarousel.length">
						<vueper-slide v-for="(slide, i) in categCarousel" :key="i" :title="slide.heading" :content="slide.title" :image="slide.image_url" :link="slide.item_url" />
					</vueper-slides>
					<p v-else class="info-box no-articles">No articles yet in this category</p>
				</div>
				<div style="text-align: center; margin: 0px -23px 15px">
					<ins class="adsbygoogle" style="display:inline-block;width:320px;height:50px" data-ad-client="ca-pub-0837986485147112" data-ad-slot="7172764154"></ins>
				</div>
				<ul class="articles-grid clearfix" v-if="otherNews.length">
					<li class="articles-grid--item" 
						v-for="entry in otherNews"
						:key="entry.id"
					>
						<a :href=entry.item_url>
						<h3 v-html="entry.title"></h3>
						<img :src=entry.thumb_url :alt=entry.title>
						</a>
					</li>
				</ul>
				<div style="text-align: center; margin: 0px -23px 15px">
					<ins class="adsbygoogle" style="display:inline-block;width:300px;height:250px" data-ad-client="ca-pub-0837986485147112" data-ad-slot="1920437471"></ins>
				</div>
				<ul class="articles-list clearfix" v-if="newsList.length">
					<li class="articles-list--item" 
						v-for="entry in newsList"
						:key="entry.id"
					>
						<a :href=entry.item_url>
						<img :src=entry.thumb_url :alt=entry.title>
						<h3 v-html="entry.title"></h3>
						<p v-html="entry.subtitle"></p>
						</a>
					</li>
				</ul>
				<div style="text-align: center; margin: 0px -23px 15px">
					<ins class="adsbygoogle" style="display:inline-block;width:300px;height:250px" data-ad-client="ca-pub-0837986485147112" data-ad-slot="8937611273"></ins>
				</div>
				<div id="footer">
					<p>&copy;<span ref="copyYear"></span> uPolitics<br>
					Breaking Political News, Interviews &amp; Videos</p>
					<img src="/assets/images/main-logo.png" class="bottom-logo">
				</div>
			</div>
		</ion-content>
	</ion-page>
</template>

<script lang="ts">
import { IonButtons, IonContent, IonMenuButton, IonPage } from '@ionic/vue';
import axios from "axios";
import { jsonDataURL, loadAds } from '@uinterview/upolitics'
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'


export default {
	name: 'Folder',
	components: {
		IonButtons,
		IonContent,
		IonMenuButton,
		IonPage,
		VueperSlides,
		VueperSlide
	},
	data() {
		return {
			componentKey: 0,
			categCarousel: [],
			otherNews: [],
			newsList: [],
			jsonResp: []
		};
	},
	mounted() {
		loadAds()
		const d = new Date
		this.$refs.copyYear.innerHTML = d.getFullYear()
		if (localStorage.appData) {
			const dateNow = Date.now()
			const jsonExpire = (JSON.parse(localStorage.appData).expires)*1000
			if(dateNow <= jsonExpire) {
				this.entriesList = JSON.parse(localStorage.appData)
				this.categCarousel = Object.values(this.entriesList[this.$route.params.id]).slice(0,3)
				this.otherNews = Object.values(this.entriesList[this.$route.params.id]).slice(1,5)
				this.newsList = Object.values(this.entriesList[this.$route.params.id]).slice(6)
				if(this.categCarousel.length) {
					setTimeout(() => this.$refs.newsSlider.init(), 100);
				}
			} else {
				axios.get(jsonDataURL)
					.then(response => {
						this.entriesList = response.data
						localStorage.setItem('appData', JSON.stringify(response.data));
						this.categCarousel = Object.values(this.entriesList[this.$route.params.id]).slice(0,3)
						this.otherNews = Object.values(this.entriesList[this.$route.params.id]).slice(1,5)
						this.newsList = Object.values(this.entriesList[this.$route.params.id]).slice(6)
						if(this.categCarousel.length) {
							setTimeout(() => this.$refs.newsSlider.init(), 100);
						}

					})
			}
		} else {
			axios.get(jsonDataURL)
				.then(response => {
					this.entriesList = response.data
					localStorage.setItem('appData', JSON.stringify(response.data));
					this.categCarousel = Object.values(this.entriesList[this.$route.params.id]).slice(0,3)
					this.otherNews = Object.values(this.entriesList[this.$route.params.id]).slice(1,5)
					this.newsList = Object.values(this.entriesList[this.$route.params.id]).slice(6)
					if(this.categCarousel.length) {
						setTimeout(() => this.$refs.newsSlider.init(), 100);
					}
				})
		}
	},
	watch: {
		'$route' (to) {
			if(to.path == '/') {
				if (localStorage.appData) {
					this.entriesList = JSON.parse(localStorage.appData)
				} else {
					axios.get(jsonDataURL)
						.then(response => {
							this.entriesList = response.data
							localStorage.setItem('appData', JSON.stringify(response.data));
							setTimeout(() => this.$refs.homeSlider.init(), 100);
						})
				}
			} else if(to.path.includes('/category/')){
				if (localStorage.appData) {
					this.entriesList = JSON.parse(localStorage.appData)
					this.categCarousel = Object.values(this.entriesList[this.$route.params.id]).slice(0,3)
					this.otherNews = Object.values(this.entriesList[this.$route.params.id]).slice(1,5)
					this.newsList = Object.values(this.entriesList[this.$route.params.id]).slice(6)
				} else {
					axios.get(jsonDataURL)
						.then(response => {
							this.entriesList = response.data
							localStorage.setItem('appData', JSON.stringify(response.data));
							this.categCarousel = Object.values(this.entriesList[this.$route.params.id]).slice(0,3)
							this.otherNews = Object.values(this.entriesList[this.$route.params.id]).slice(1,5)
							this.newsList = Object.values(this.entriesList[this.$route.params.id]).slice(6)
						})
				}
			} else if(to.path.includes('/page/')){
				// do nothing
			} else if(to.path.includes('/videos/')){
				if (localStorage.appData) {
					this.entriesList = JSON.parse(localStorage.appData)
					this.article = this.entriesList.videos[this.$route.params.id]
					this.otherNews = Object.values(this.entriesList.videos).slice(0,6)
					this.otherNews2 = Object.values(this.entriesList.videos).slice(6,18)
				} else {
					axios.get(jsonDataURL)
						.then(response => {
							this.entriesList = response.data
							localStorage.setItem('appData', JSON.stringify(response.data));
							this.article = this.entriesList.videos[this.$route.params.id]
							this.otherNews = Object.values(this.entriesList.videos).slice(0,6)
							this.otherNews2 = Object.values(this.entriesList.videos).slice(6,18)
								})
				}
			} else if(to.path.includes('/polls/')){
				if (localStorage.appData) {
					this.entriesList = JSON.parse(localStorage.appData)
					this.article = this.entriesList.polls[this.$route.params.id]
					this.otherNews = Object.values(this.entriesList.polls).slice(0,6)
					this.otherNews2 = Object.values(this.entriesList.polls).slice(6,18)
				} else {
					axios.get(jsonDataURL)
						.then(response => {
							this.entriesList = response.data
							localStorage.setItem('appData', JSON.stringify(response.data));
							this.article = this.entriesList.polls[this.$route.params.id]
							this.otherNews = Object.values(this.entriesList.polls).slice(0,6)
							this.otherNews2 = Object.values(this.entriesList.polls).slice(6,18)
								})
				}
			} else if(to.path.includes('/polls/')){
				if (localStorage.appData) {
					this.entriesList = JSON.parse(localStorage.appData)
					this.article = this.entriesList.polls[this.$route.params.id]
					this.otherNews = Object.values(this.entriesList.polls).slice(0,6)
					this.otherNews2 = Object.values(this.entriesList.polls).slice(6,18)
				} else {
					axios.get(jsonDataURL)
						.then(response => {
							this.entriesList = response.data
							localStorage.setItem('appData', JSON.stringify(response.data));
							this.article = this.entriesList.polls[this.$route.params.id]
							this.otherNews = Object.values(this.entriesList.polls).slice(0,6)
							this.otherNews2 = Object.values(this.entriesList.polls).slice(6,18)
						})
				}
			} else if(to.path.includes('/coronavirus/')){
				if (localStorage.appData) {
					this.entriesList = JSON.parse(localStorage.appData)
					this.article = this.entriesList.coronavirus[this.$route.params.id]
					this.otherNews = Object.values(this.entriesList.coronavirus).slice(0,6)
					this.otherNews2 = Object.values(this.entriesList.coronavirus).slice(6,18)
				} else {
					axios.get(jsonDataURL)
						.then(response => {
							this.entriesList = response.data
							localStorage.setItem('appData', JSON.stringify(response.data));
							this.article = this.entriesList.coronavirus[this.$route.params.id]
							this.otherNews = Object.values(this.entriesList.coronavirus).slice(0,6)
							this.otherNews2 = Object.values(this.entriesList.coronavirus).slice(6,18)
						})
				}
			} else if(to.path.includes('/ubio/')){
				if (localStorage.appData) {
					this.entriesList = JSON.parse(localStorage.appData)
					this.article = this.entriesList.ubio[this.$route.params.id]
					this.otherNews = Object.values(this.entriesList.ubio).slice(0,6)
					this.otherNews2 = Object.values(this.entriesList.ubio).slice(6,18)
				} else {
					axios.get(jsonDataURL)
						.then(response => {
							this.entriesList = response.data
							localStorage.setItem('appData', JSON.stringify(response.data));
							this.article = this.entriesList.ubio[this.$route.params.id]
							this.otherNews = Object.values(this.entriesList.ubio).slice(0,6)
							this.otherNews2 = Object.values(this.entriesList.ubio).slice(6,18)
								})
				}
			} else if(to.path.includes('/opinion/')){
				if (localStorage.appData) {
					this.entriesList = JSON.parse(localStorage.appData)
					this.article = this.entriesList.opinion[this.$route.params.id]
					this.otherNews = Object.values(this.entriesList.opinion).slice(0,6)
					this.otherNews2 = Object.values(this.entriesList.opinion).slice(6,18)
				} else {
					axios.get(jsonDataURL)
						.then(response => {
							this.entriesList = response.data
							localStorage.setItem('appData', JSON.stringify(response.data));
							this.article = this.entriesList.opinion[this.$route.params.id]
							this.otherNews = Object.values(this.entriesList.opinion).slice(0,6)
							this.otherNews2 = Object.values(this.entriesList.opinion).slice(6,18)
								})
				}
			} else if(to.path.includes('/congress/')){
				if (localStorage.appData) {
					this.entriesList = JSON.parse(localStorage.appData)
					this.article = this.entriesList.congress[this.$route.params.id]
					this.otherNews = Object.values(this.entriesList.congress).slice(0,6)
					this.otherNews2 = Object.values(this.entriesList.congress).slice(6,18)
				} else {
					axios.get(jsonDataURL)
						.then(response => {
							this.entriesList = response.data
							localStorage.setItem('appData', JSON.stringify(response.data));
							this.article = this.entriesList.congress[this.$route.params.id]
							this.otherNews = Object.values(this.entriesList.congress).slice(0,6)
							this.otherNews2 = Object.values(this.entriesList.congress).slice(6,18)
						})
				}
			} else {
				if (localStorage.appData) {
					this.entriesList = JSON.parse(localStorage.appData)
					this.article = this.entriesList.news[this.$route.params.id]
					this.otherNews = Object.values(this.entriesList.news).slice(0,6)
					this.otherNews2 = Object.values(this.entriesList.news).slice(6,18)
				} else {
					axios.get(jsonDataURL)
						.then(response => {
							this.entriesList = response.data
							localStorage.setItem('appData', JSON.stringify(response.data));
							this.article = this.entriesList.news[this.$route.params.id]
							this.otherNews = Object.values(this.entriesList.news).slice(0,6)
							this.otherNews2 = Object.values(this.entriesList.news).slice(6,18)
						})
				}
				// this.$refs.commentsButton.style.display = 'block'
				// this.$refs.blogCommentForm.style.display = 'none'
				// this.$refs.commentsContainer.innerHTML = ''
			}
			this.$el.querySelector("#container").scrollIntoView({behavior: 'smooth'});
		}
	}

};
</script>

<style>
	@import '/assets/css/main.css';
</style>
